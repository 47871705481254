<template>
  <div>
    Loading...
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'AdventureDirectJoin',
  data() {
    return {
      directJoinCode: this.$route.query.directJoinCode,
      gameCode: this.$route.query.gameCode,
      teamName: this.$route.query.teamName
    }
  },
  mounted () {
    const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
    masterFunction({
      methodName: 'create-team-direct-join',
      directJoinCode: this.directJoinCode,
      gameCode: this.gameCode,
      teamName: this.teamName
    }).then(result => {
      if (result.data.teamCode) {
        const teamCode = result.data.teamCode;
        window.location.href = `${window.location.origin.split('/')[0]}/lobby/${encodeURIComponent(teamCode)}?teamName=${encodeURIComponent(this.teamName)}&directJoin=true`;
      }
    })
  }
}
</script>
